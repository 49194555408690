exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-installation-tsx": () => import("./../../../src/pages/installation.tsx" /* webpackChunkName: "component---src-pages-installation-tsx" */),
  "component---src-pages-legal-terms-tsx": () => import("./../../../src/pages/legal/terms.tsx" /* webpackChunkName: "component---src-pages-legal-terms-tsx" */),
  "component---src-pages-qe-consulting-tsx": () => import("./../../../src/pages/qe-consulting.tsx" /* webpackChunkName: "component---src-pages-qe-consulting-tsx" */),
  "component---src-pages-test-automation-as-a-service-tsx": () => import("./../../../src/pages/test-automation-as-a-service.tsx" /* webpackChunkName: "component---src-pages-test-automation-as-a-service-tsx" */),
  "component---src-pages-testing-as-a-service-tsx": () => import("./../../../src/pages/testing-as-a-service.tsx" /* webpackChunkName: "component---src-pages-testing-as-a-service-tsx" */),
  "component---src-templates-accelerators-d-4-j-tsx": () => import("./../../../src/templates/accelerators/d4j.tsx" /* webpackChunkName: "component---src-templates-accelerators-d-4-j-tsx" */),
  "component---src-templates-accelerators-hlm-tsx": () => import("./../../../src/templates/accelerators/hlm.tsx" /* webpackChunkName: "component---src-templates-accelerators-hlm-tsx" */),
  "component---src-templates-accelerators-qasp-tsx": () => import("./../../../src/templates/accelerators/qasp.tsx" /* webpackChunkName: "component---src-templates-accelerators-qasp-tsx" */),
  "component---src-templates-blog-post-blog-post-tsx": () => import("./../../../src/templates/blog-post/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx" */),
  "component---src-templates-case-study-case-study-tsx": () => import("./../../../src/templates/case-study/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-case-study-tsx" */),
  "component---src-templates-contact-us-contact-us-tsx": () => import("./../../../src/templates/contact-us/contact-us.tsx" /* webpackChunkName: "component---src-templates-contact-us-contact-us-tsx" */),
  "component---src-templates-pricing-on-premises-tsx": () => import("./../../../src/templates/pricing/on-premises.tsx" /* webpackChunkName: "component---src-templates-pricing-on-premises-tsx" */),
  "component---src-templates-pricing-saas-tsx": () => import("./../../../src/templates/pricing/saas.tsx" /* webpackChunkName: "component---src-templates-pricing-saas-tsx" */),
  "component---src-templates-sponsorship-program-business-tsx": () => import("./../../../src/templates/sponsorship-program/business.tsx" /* webpackChunkName: "component---src-templates-sponsorship-program-business-tsx" */),
  "component---src-templates-sponsorship-program-individual-tsx": () => import("./../../../src/templates/sponsorship-program/individual.tsx" /* webpackChunkName: "component---src-templates-sponsorship-program-individual-tsx" */)
}

